@import "../../../styles/variables";
@import "../../../styles/typography";

.explore-item {
  border-bottom: 1px solid $color-black;
  padding-bottom: rem(25px);
  padding-top: rem(25px);

  @include mq-mobile() {
    padding-bottom: rem(50px);
    padding-top: rem(50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:nth-child(odd) {
      padding-right: rem(50px);
      border-right: 1px solid $color-black;
    }

    &:nth-child(even) {
      padding-left: rem(50px);
    }
  }
}

.explore-item__tagline {
  display: block;
  margin-bottom: rem(30px);
}

.explore-item__image {
  margin-bottom: rem(20px);

  img {
    width: 100%;
    height: 100%;
  }
}

.explore-item__title {
  margin-bottom: rem(20px);
  font-size: rem(32px);
}

.explore-item__description {
  margin-bottom: rem(20px);
}

.explore-item__cta {
  display: inline-block;

  span {
    display: inline-block;
    transition: 300ms transform;
  }

  &:hover {
    span {
      transform: translate3d(4px, 0, 0);
    }
  }
}
