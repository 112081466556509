@import "../../styles/variables";
@import "../../styles/typography";

.themes-desktop {
    display: none;

    @include mq-menu() {
        display: block;
    }
}

.themes-mobile {
    display: block;

    @include mq-menu() {
        display: none;
    }
}

// MOBILE
.themes-blocks__item {
    position: relative;
    height: rem(500px);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
}

.theme-blocks__item-title {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: rem(48px);
    text-transform: uppercase;
    color: $color-white;
}

.theme-blocks__item-month {
    position: relative;
    z-index: 1;
    color: $color-white;
}

.theme-blocks__item-arrow {
    position: relative;
    z-index: 1;

    margin-top: rem(20px);

    border: none;
    color: black;
    background: white;
    border-radius: 50%;

    width: rem(40px);
    height: rem(40px);

    display: flex;
    align-items: center;
    justify-content: center;
}

// DESKTOP
.themes-background__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    &:not(:first-of-type) {
        opacity: 0;
    }
}

.themes {
    position: relative;

    width: 100%;
    height: 100%;

    min-width: 100vw;
    min-height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.themes-wrapper {
}

.theme-circles {
    position: relative;

    width: $width-circle;
    height: $width-circle;
}

.theme-circles__item {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    border: 2px solid white;
    border-radius: 50%;

    opacity: 0.5;
    transition: 200ms opacity ease-in-out;

    cursor: pointer;

    @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
            left: calc(#{$i} * 5%);
            top: calc(#{$i} * 5%);

            width: calc(100% - (#{$i} * 10%));
            height: calc(100% - (#{$i} * 10%));
        }
    }

    &:hover,
    &--active {
        opacity: 1 !important;

        .theme-circles__item-arrow {
            opacity: 1;
        }
    }
}

.theme-circles__item-arrow {
    position: absolute;
    top: calc(50% - 20px);
    right: rem(-20px);

    border: none;
    color: black;
    background: white;
    border-radius: 50%;

    width: rem(40px);
    height: rem(40px);

    display: flex;
    align-items: center;
    justify-content: center;

    transition: 200ms transform ease-in-out, 200ms opacity ease-in-out;

    opacity: 0;
}

.theme-active {
    position: absolute;
    top: calc(50% - (#{$width-circle}/ 2));
    left: calc(50% + (#{$width-circle}/ 2) + #{rem(10px)});
    z-index: 2;

    display: flex;
    align-items: center;

    height: $width-circle;

    color: $color-white;

    @include mq-tablet {
        left: calc(50% + (#{$width-circle}/ 2) + #{rem(50px)});
    }
}

.theme-active-wrapper {
    position: absolute;
    min-width: 200px;

    &:not(:first-of-type) {
        opacity: 0;
        visibility: hidden;
    }
}

.theme-active__name {
    padding: 0;
    margin: 0;
    font-size: rem(36px);
    text-transform: uppercase;
}

.theme-active__month {
    span {
        &:first-child {
            margin-right: rem(4px);
        }
    }
}

.themes-background__credit {
    position: absolute;
    bottom: rem(25px);
    right: $padding-desktop-small;
    z-index: 2;

    color: $color-white;
}
