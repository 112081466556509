@import "../../../styles/variables";
@import "../../../styles/typography";

.footer {
    position: relative;
    z-index: 2;
    border-top: 2px solid $color-black;

    margin-top: rem(50px);
    padding-top: rem(50px);
    padding-bottom: rem(25px);

    background: $color-background;

    @include mq-mobile() {
        margin-top: rem(150px);
        padding-top: rem(100px);
    }
}

.footer-content__info {
    @include mq-mobile() {
        width: 75%;
    }
}

.footer-navigation {
    margin-top: rem(50px);

    & > * {
        display: block;
        margin-bottom: rem(15px);
    }

    @include mq-mobile() {
        text-align: right;

        & > * {
            display: inline-block;
            margin-left: rem(15px);
            margin-bottom: 0;
        }
    }
}

.footer-content__info-cta {
    margin-top: rem(20px);
    display: flex;

    a {
        position: relative;
        white-space: nowrap;

        &:before {
            content: "";

            position: absolute;
            left: 0;
            bottom: rem(-2px);

            width: 100%;
            height: 1px;

            background: $color-black;
            transform: scaleX(0);
            opacity: 0;
            transition: 300ms transform ease-in-out, 300ms opacity ease-in-out;
        }

        &:hover {
            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }
}

.footer-content__info-arrow {
    margin-right: rem(10px);
}

.footer-instagram {
    display: flex;
    overflow-x: auto;
    height: 300px;

    margin: rem(100px) 0;
    margin-left: rem(-25px);
}

.footer-instagram__item {
    position: relative;
    text-align: center;
    width: calc(100% / 3);

    @include mq-mobile() {
        width: calc(100% / 6);
    }

    img {
        border-radius: 50%;
        object-fit: cover;
    }

    &:nth-child(odd) {
        top: 0;
    }

    &:nth-child(even) {
        padding-top: rem(100px);
    }
}
