@import "../../../styles/variables";
@import "../../../styles/typography";

.theme-content {
    position: relative;
    background: $color-background;
}

.theme-content__introduction {
    text-align: center;

    p {
        &:not(:last-of-type) {
            margin-bottom: rem(20px);
        }
    }

    @include mq-mobile() {
        width: 70%;
        margin: 0 auto;
    }
}

.theme-content__main {
    @include mq-mobile() {
        padding: 0 rem(100px);
    }
}

.theme-content__top {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 8;

    @include mq-mobile() {
        display: none;
    }
}

.theme-content__top-cta {
    width: rem(30px);
    height: rem(30px);
    font-size: rem(20px);
    line-height: rem(26px);

    transform: translate3d(0, 30px, 0);
    opacity: 0;
}
