@import "./variables";

html,
body {
    padding: 0;
    margin: 0;
    background: $color-background;
    color: $color-black;
    font-family: $font-regular;
    overscroll-behavior-y: contain;
}

html {
    -webkit-font-smoothing: antialiased;
    -khtml-font-smoothing: antialiased;
    -apple-font-smoothing: antialiased;

    overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

* {
    box-sizing: border-box;
    letter-spacing: rem(-0.25px);
}

p,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    padding-inline-start: 0;
    padding-inline-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
}

li {
    list-style-type: none;
}

input {
    background: none;
    border: none;
}

button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    &:focus {
        outline: none;
    }
}
