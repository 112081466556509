@import "../../styles/variables";
@import "../../styles/layout";
@import "../../styles/typography";

.members-list {
  border-collapse: collapse;
  display: none;
  width: 100%;

  th {
    text-align: left;
  }

  th,
  td {
    padding: rem(15px) rem(5px) rem(15px) 0;
    border-collapse: collapse;
    border-bottom: 1px solid $color-black;
  }

  &--mobile {
    display: block;

    @include mq-mobile() {
      display: none;
    }
  }

  @include mq-mobile() {
    display: table;
  }
}

.members-list__column {
  min-width: rem(70px);
  display: inline-block;

  font-size: rem(14px);
}

.members-list-option__item {
  display: flex;
  margin-bottom: rem(10px);

  @include mq-mobile() {
    display: block;
  }
}

.members-list__option-value {
  font-size: rem(14px);
  padding-right: rem(10px);
}

.members-list__title {
  margin-bottom: rem(20px);

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
