@import "../../../styles/variables";

.accordion {
  border-bottom: 1px solid $color-black;

  &--is-open {
    .accordion-options {
      display: block;
    }

    .accordion-button__arrow {
      transform: rotate(180deg);
    }
  }
}

.accordion-button {
  position: relative;
  text-align: left;

  width: 100%;

  border: none;
  padding: rem(15px) 0;
  margin: 0;

  background: none;

  &:hover {
    background: none;
  }
}

.accordion-button__arrow {
  position: absolute;
  right: 0;
  top: calc(50% - #{rem(8px)});

  width: rem(10px);
  height: rem(20px);

  svg {
    stroke: $color-black;
  }
}

.accordion-options {
  width: 100%;
  padding: rem(15px) 0;
  display: none;
}
