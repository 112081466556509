@import "../../../styles/variables";
@import "../../../styles/typography";

.header {
    position: absolute;
    z-index: $z-index-header;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 0 $padding-mobile-small;

    a,
    button {
        color: $color-black;
    }

    &--is-white {
        a,
        button {
            color: $color-white;
        }

        .navigation-list__item {
            a:before,
            button:before {
                background: $color-white;
            }
        }

        .mobile-button {
            .mobile-button__circle {
                border-color: $color-white;
            }
        }
    }
}

.navigation {
    display: none;

    a,
    button {
        position: relative;

        &:before {
            content: "";

            position: absolute;
            left: 0;
            bottom: rem(-2px);

            width: 100%;
            height: 1px;

            background: $color-black;
            transform: scaleX(0);
            opacity: 0;
            transition: 300ms transform ease-in-out, 300ms opacity ease-in-out;
        }

        &:hover,
        &.active {
            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }

    @include mq-mobile() {
        position: absolute;
        top: rem(10px);
        right: 0;
        padding-right: $padding-desktop-small;
        z-index: 3;

        display: block;
    }
}

.navigation-list {
    display: inline-block;
}

.navigation-list__item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: rem(30px);
    }
}

.logo {
    position: fixed;
    top: rem(40px);
    left: $padding-mobile-small;
    z-index: $z-index-header;

    line-height: 0;

    @include mq-mobile() {
        top: rem(24px);
    }
}

.mobile-button {
    position: absolute;
    top: rem(30px);
    right: $padding-mobile-small;

    z-index: 3;

    &--is-open {
        .mobile-button__circle {
            background: $color-black;
        }
    }

    @include mq-mobile() {
        display: none;
    }
}

.mobile-button__circle {
    width: rem(20px);
    height: rem(20px);

    border: 1px solid $color-black;
    border-radius: 50%;

    background: transparent;
    transition: 300ms background-color ease-in-out;
}

.navigation-mobile {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    background: $color-background;

    padding: 100px $padding-mobile-small 0 $padding-mobile-small;

    visibility: hidden;
    opacity: 0;

    transition: 300ms visibility ease-in-out, 300ms opacity ease-in-out;

    a,
    button {
        position: relative;

        &:before {
            content: "";

            position: absolute;
            left: 0;
            bottom: rem(-2px);

            width: 100%;
            height: 1px;

            background: $color-black;
            transform: scaleX(0);
            opacity: 0;
            transition: 300ms transform ease-in-out, 300ms opacity ease-in-out;
        }

        &:hover,
        &.active {
            &:before {
                transform: scaleX(1);
                opacity: 1;
            }
        }
    }

    &--is-open {
        visibility: visible;
        opacity: 1;
    }

    @include mq-mobile() {
        display: none;
    }
}

.navigation-mobile-list__item {
    font-size: rem(20px);
    margin-bottom: rem(30px);
}
