@import "../../../styles/variables";
@import "../../../styles/typography";

.about {
  padding-top: rem(100px);
  overflow-x: hidden;

  @include mq-mobile() {
    padding-top: rem(200px);
  }
}

.about-headline {
  position: relative;
}

.about-section {
  margin-bottom: rem(40px);

  @include mq-mobile() {
    margin-bottom: rem(100px);
  }
}

.about-headline__title {
  @include mq-mobile() {
    width: 60%;
  }
}

.about-circle {
  display: none;

  @include mq-mobile() {
    position: absolute;

    width: rem(300px);
    height: rem(300px);

    border: 1px solid $color-black;
    border-radius: 50%;

    display: block;

    &--right {
      top: rem(-100px);
      right: rem(-150px);
    }

    &--left {
      top: 0;
      left: rem(-150px);
    }
  }
}

.about-content {
  position: relative;
}

.about-content__text {
  p {
    &:not(:last-of-type) {
      margin-bottom: rem(20px);
    }
  }

  h2 {
    font-size: rem(24px);
    line-height: rem(36px);
    margin-bottom: rem(10px);

    @include mq-mobile() {
      font-size: rem(45px);
      line-height: rem(54px);

      margin-bottom: rem(20px);
    }
  }

  button {
    margin-top: rem(20px);
  }

  @include mq-mobile() {
    width: 50%;
    padding-right: rem(100px);

    &--right {
      margin-left: 50%;
      padding-right: 0;
    }
  }
}

.about-content__image {
  width: 70%;
  margin: 0 auto;
  margin-top: rem(50px);

  @include mq-mobile() {
    position: absolute;
    left: 50%;
    bottom: rem(-50px);

    margin: 0;

    display: flex;
  }

  img {
    width: 100%;
    height: 100%;

    @include mq-mobile() {
      max-width: rem(250px);
    }
  }
}

.about-content__image-caption {
  padding-top: rem(10px);

  @include mq-mobile() {
    padding-left: rem(20px);
    padding-top: 0;
    width: 30%;
  }
}

.about-content__text-button {
  display: block;
  margin-top: rem(30px);
}
