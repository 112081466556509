@import "../../styles/layout";
@import "../../styles/typography";

.account-section {
  margin-bottom: rem(60px);
}

.account-section__title {
  margin-bottom: rem(20px);
}

.account-section-list__item {
  margin-bottom: rem(30px);
}

.account-section-list__item-key {
  display: block;
  font-size: rem(12px);
}

.account-section-list__item-value {
  display: block;
  margin-top: rem(5px);
}
