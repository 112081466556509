@import "../../../styles/variables";

.cookie-notification {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-index-cookie;

    color: $color-background;

    width: 100%;
    padding: rem(20px) $padding-mobile-small;

    opacity: 0;
    visibility: hidden;

    @include mq-mobile() {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.cookie-notification__content {
    margin-bottom: rem(30px);

    @include mq-mobile() {
        padding-right: rem(20px);
        margin-bottom: 0;
    }

    p {
        &:first-child {
            margin-bottom: rem(10px);
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.cookie-notification__cta {
    margin-right: rem(20px);
}
