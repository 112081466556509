@import "../../../styles/variables";
@import "../../../styles/layout";
@import "../../../styles/typography";

.content-title {
    text-transform: uppercase;
    margin-bottom: rem(40px);
}

.content-richtext {
    @include mq-mobile() {
        width: 75%;
    }

    ol li {
        list-style-type: decimal;
    }

    ul li {
        list-style-type: disc;
    }

    li {
        margin-bottom: rem(15px);
    }
}
