/* COLORS */
$color-white: #fff;
$color-black: #2b2625;

$color-background: #f6f2e8;

/* FONTS */
$font-regular: "Whyte Book";

/* Z-INDEX */
$z-index-header: 8;
$z-index-intro: 6;
$z-index-theme-overlay: 7;
$z-index-cookie: 4;

/* UI */
$padding-mobile-small: 30px;
$padding-mobile-medium: 100px;

$padding-desktop-small: 3vw;
$padding-desktop-medium: 5vw;

$width-circle: 500px;

/* 
**  5 - BREAKPOINTS 
*/
$bp-narrow: 480px;
$bp-mobile: 768px;
$bp-menu: 900px;
$bp-tablet: 1024px;

@mixin mq-extra-narrow {
    @media (max-width: $bp-narrow) {
        @content;
    }
}

@mixin mq-narrow {
    @media (min-width: $bp-narrow + 1) {
        @content;
    }
}

@mixin mq-mobile {
    @media (min-width: $bp-mobile + 1) {
        @content;
    }
}

@mixin mq-menu {
    @media (min-width: $bp-menu + 1) {
        @content;
    }
}

@mixin mq-tablet {
    @media (min-width: $bp-tablet + 1) {
        @content;
    }
}
/* 
**  5 - BREAKPOINTS 
*/
$base-font-size: 16px;
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}
