@import "../../../styles/variables";
@import "../../../styles/typography";

.pillars {
  display: none;
  overflow-x: hidden;

  @include mq-mobile() {
    display: flex;
    margin-top: rem(100px);
  }
}

.pillar {
  position: absolute;
  width: 38vw;
  height: 38vw;

  border: 1px solid $color-black;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &:nth-child(1) {
    left: -5vw;
  }

  &:nth-child(2) {
    left: calc(50% - 19vw);
  }

  &:nth-child(3) {
    right: -5vw;
  }
}

.pillar-text {
  font-size: 2vw;
}

.pillars-list {
  margin-top: rem(20px);
  @include mq-mobile() {
    margin-top: 50vw;
  }
}

.pillars-list__item {
  margin-bottom: rem(30px);

  @include mq-mobile() {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(60px);
  }
}

.pillars-list__item-title {
  margin-bottom: rem(10px);

  @include mq-mobile() {
    width: 45%;
    margin-bottom: 0;
  }
}

.pillars-list__item-description {
  @include mq-mobile() {
    width: 45%;
  }
}
