@import "../../styles/variables";

.home-content {
    position: relative;
    padding-left: $padding-mobile-small;
    height: 100%;
    min-height: 100vh;

    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    @include mq-mobile() {
        position: absolute;
        left: 0;
        bottom: rem(50px);

        display: block;
        height: auto;
        min-height: auto;
    }

    a {
        &:not(:last-of-type) {
            margin-bottom: rem(10px);
        }

        @include mq-mobile() {
            display: inline-block;

            &:not(:last-of-type) {
                margin-right: rem(10px);
                margin-bottom: 0;
            }
        }
    }
}

.home-content__title {
    font-weight: 300;
    font-size: rem(24px);
    margin-bottom: rem(40px);
    color: $color-white;
    max-width: 90%;

    @include mq-mobile() {
        font-size: rem(48px);
        line-height: rem(48px);
        margin-bottom: rem(40px);
        max-width: 75%;
    }
}
