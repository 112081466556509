@import "./variables";

.page-content {
  padding-top: rem(100px);
  padding-bottom: rem(100px);
  overflow-x: hidden;

  @include mq-mobile() {
    padding-top: rem(200px);
    padding-bottom: rem(200px);
  }
}

.center {
  text-align: center;
}

// Form stuff
.form-container {
  @include mq-mobile() {
    width: 50%;
    margin: 0 auto;
  }
}

.form-title {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: rem(50px);

  &--left {
    text-align: left;
  }
}

.form-row {
  margin-bottom: rem(20px);

  &--handle {
    position: relative;

    &:before {
      content: "@";
      position: absolute;
      z-index: 1;
      font-size: rem(12px);
      top: rem(46px);
      left: rem(8px);
    }
  }

  a {
    text-decoration: underline;
  }
}

.form-row__field {
  width: 100%;
  background: $color-white;
  font-family: $font-regular;
  padding: rem(15px) rem(20px);
  border: 1px solid #dcd5c9;

  &:focus {
    border: 1px solid $color-black;
    outline: none;
  }
}

.form-row__label {
  display: block;
  margin-bottom: rem(10px);
}

.form-buttons {
  margin-top: rem(50px);

  button {
    margin-bottom: rem(20px);
  }
}

.form-buttons__message {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-buttons__optional {
  font-size: rem(12px);
  margin-bottom: rem(10px);
}

.form-error {
  text-align: center;
  margin-bottom: rem(50px);
  color: red;

  &--left {
    text-align: left;
  }
}

.form-success {
  text-align: center;
  margin-bottom: rem(50px);

  &--left {
    text-align: left;
  }
}
