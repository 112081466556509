@import "../../../styles/variables";

.aside-layout {
  position: relative;
  @include mq-mobile() {
    display: flex;
  }
}

.aside-layout__aside-scroll {
  @include mq-mobile() {
    position: fixed;
    top: rem(200px);
    left: $padding-mobile-small;
  }
}

.aside-layout__aside {
  position: relative;

  h1 {
    font-size: rem(40px);
  }

  @include mq-mobile() {
    width: 35%;
    padding-right: rem(50px);
  }
}

.aside-layout__content {
  margin-top: rem(40px);

  @include mq-mobile() {
    width: 65%;

    margin-top: 0;
  }
}
