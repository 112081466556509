@import "../../styles/variables";
@import "../../styles/typography";

.text {
  margin-bottom: rem(30px);
  @include mq-mobile() {
    margin-bottom: rem(50px);
  }

  &--left {
    @include mq-mobile() {
      width: 50%;
    }
  }

  &--right {
    @include mq-mobile() {
      width: 50%;
      margin-left: 50%;
    }
  }
}
