@import "../../../styles/variables";
@import "../../../styles/typography";

.theme-navigation {
    // text-align: center;
    // margin-top: rem(60px);
    // margin-bottom: rem(10px);
    padding-bottom: rem(40px);

    @include mq-mobile() {
        position: fixed;
        top: rem(20px);
        left: 0;
        z-index: 2;

        align-self: stretch;

        margin-top: 0;
        margin-bottom: 0;

        padding-top: rem(100px);

        display: flex;
        align-items: center;
    }
}

.theme-navigation__title {
    text-transform: uppercase;
    margin-bottom: rem(10px);
    text-align: center;

    @include mq-mobile() {
        display: inline-block;

        transform: rotate(-90deg);
        height: 0;
        margin-bottom: 0;
        margin-left: rem(-10px);
    }
}

.theme-navigation-list {
    display: flex;
    justify-content: center;

    @include mq-mobile() {
        margin-left: rem(-15px);

        display: initial;
    }
}

.theme-navigation-list__item {
    width: rem(40px);
    height: rem(40px);

    border: 1px solid $color-black;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: rem(14px);

    transition: 300ms background-color ease-in-out, 300ms color ease-in-out;
    pointer-events: all;

    &:not(:last-of-type) {
        margin-right: rem(10px);

        @include mq-mobile() {
            margin-right: 0;
            margin-bottom: rem(10px);
        }
    }

    &--is-active,
    &:hover {
        background: $color-black;
        color: $color-white;
    }
}
