@import "../../styles/variables";
@import "../../styles/typography";

.home-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;

    min-width: 100vw;
    min-height: 100vh;
}

.backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;

    width: 100%;
    height: 100%;
}

.backgrounds__image {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;

    pointer-events: none;

    &:not(:first-of-type) {
        opacity: 0;
    }
}

.credits {
    position: absolute;
    bottom: rem(25px);
    right: $padding-desktop-small;
    z-index: 1;

    width: 100%;
    max-width: rem(300px);
    text-align: right;

    @include mq-mobile() {
        left: initial;
        right: $padding-desktop-small;
    }
}

.credits__item {
    position: absolute;
    bottom: 0;
    left: 0;
    color: $color-white;

    width: 100%;

    &:not(:first-of-type) {
        opacity: 0;
    }

    @include mq-mobile() {
        text-align: right;
        max-width: rem(300px);
    }
}
