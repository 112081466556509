@import "../../../styles/variables";

.question {
    margin-bottom: 10vw;
}

.question__title {
    text-align: center;
    padding-top: rem(100px);
    padding-bottom: rem(40px);
    font-size: 8vw;

    // display: flex;
    // justify-content: center;

    @include mq-mobile() {
        width: 75%;
        margin: 0 auto;
        font-size: rem(48px);
        padding-top: rem(100px);
        padding-bottom: rem(60px);
    }
}

.question__title-number {
    position: relative;
    top: rem(-12px);
    margin-right: rem(4px);
    font-size: 400;

    display: inline-block;
    font-size: rem(18px);

    @include mq-mobile() {
        top: rem(-18px);
        font-size: rem(22px);
    }
}

.question__answers {
    @include mq-mobile() {
        column-count: 3;
        column-gap: rem(30px);
    }

    @include mq-tablet() {
        column-gap: rem(70px);
    }
}
