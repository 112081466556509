@import "../../../styles/variables";
@import "../../../styles/typography";

.theme-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-theme-overlay;

    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-height: 100vh;

    padding-top: rem(30px);

    background: $color-background;
    overflow-y: scroll;

    @include mq-mobile() {
        display: flex;
        flex-direction: column;
    }
}

.theme-overlay__pagination {
    position: fixed;
    z-index: 3;
    font-size: rem(50px);
    user-select: none;
    pointer-events: none;

    display: none;

    @include mq-mobile() {
        display: block;
    }
}

.theme-overlay__bar {
    padding: rem(50px) 0;
}

.theme-overlay__bar-header {
    position: relative;

    display: flex;
    justify-content: space-between;

    a {
        &:not(:last-child) {
            margin-right: rem(20px);
        }
    }
}
.theme-overlay__bar-header__close {
    position: absolute;
    left: calc(50% - 24px);
    top: 0;
}

.theme-overlay__bar-header__answer {
    display: none;

    @include mq-mobile() {
        display: inline-block;
    }
}

.theme-overlay__content {
    @include mq-mobile() {
        flex: 1;
        display: flex;
        padding-left: rem(30px);
        padding-right: rem(100px);
    }
}

.theme-overlay__content-text {
    padding: 0 $padding-mobile-small;

    @include mq-mobile() {
        padding: 0;
        width: 40%;
    }
}

.theme-overlay__content-image {
    position: relative;
    width: 100%;
    margin-bottom: rem(20px);

    overflow: hidden;

    height: 60vh;

    @include mq-mobile() {
        margin-bottom: 0;
        margin-right: rem(100px);
        width: 60%;
        height: 100%;
    }
}

.theme-overlay__content-video {
    height: calc(87vw / 16 * 9);

    @include mq-mobile() {
        height: 100%;
    }
}

.theme-overlay__content-video__frame {
    width: 100%;
    height: 100%;

    @include mq-mobile() {
        height: calc(50vw / 16 * 9);
    }
}

.theme-overlay__content-image__item {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    img {
        // @include mq-mobile() {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 100%;
        object-fit: cover;

        @include mq-mobile() {
            object-fit: contain;
        }
        // }
    }

    &:not(:first-of-type) {
        opacity: 0;
    }
}

.theme-overlay__content-image__button {
    position: absolute;
    top: calc(50% - 20px);
    background: rgba($color-white, 0.8);
    z-index: 2;

    width: rem(40px);
    height: rem(40px);

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
        left: rem(30px);
    }

    &--right {
        right: rem(30px);
    }
}

.theme-overlay__content-question {
    margin-bottom: rem(10px);

    @include mq-mobile() {
        margin-bottom: rem(40px);
    }
}
