@import "../../../styles/variables";
@import "../../../styles/typography";

$padding: rem(70px);

.answer {
    position: relative;
    margin-bottom: rem(30px);

    break-inside: avoid;

    @include mq-tablet() {
        margin-bottom: $padding;
    }

    &:hover {
        .answer__overlay {
            opacity: 1;
        }
    }
}

.answer__image {
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;

    & > * {
        width: 100%;
        height: 100%;
    }
}

.answer__overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    color: $color-white;

    width: calc(100%);
    height: calc(100%);

    background: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;

    opacity: 0;
    transition: 300ms opacity ease-in-out;
}
