@import "../../../styles/variables";
@import "../../../styles/typography";

.button-ghost {
  border: 1px solid $color-white;
  padding: rem(20px) rem(35px);
  transition: 300ms background-color ease-in-out;
  text-transform: uppercase;

  &--link {
    display: inline;
    padding: rem(18px) rem(35px) rem(20px) rem(35px);
  }

  span {
    color: $color-white;
    transition: 300ms color ease-in-out;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  &:not([disabled]):hover {
    background: $color-white;

    span {
      color: $color-black;
    }
  }

  &--black {
    border: 1px solid $color-black;

    span {
      color: $color-black;
    }

    &:not([disabled]):hover {
      background: $color-black;

      span {
        color: $color-white;
      }
    }
  }
}
