@import "../../../styles/layout";
@import "../../../styles/typography";
@import "../../../styles/variables";

.explore-grid {
  @include mq-mobile() {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: 50%;
    }
  }
}

.explore-grid-filter {
  margin-top: rem(30px);
}

.explore-grid-filter__item {
  display: block;
  margin-bottom: rem(15px);
  transition: 300ms opacity ease-in-out;

  position: relative;

  &:before {
    content: "";

    position: absolute;
    left: 0;
    bottom: rem(-2px);

    width: 100%;
    height: 1px;

    background: $color-black;
    transform: scaleX(0);
    opacity: 0;
    transition: 300ms transform ease-in-out, 300ms opacity ease-in-out;
  }

  &--active {
    &:before {
      transform: scaleX(1);
      opacity: 1;
    }
  }
}
