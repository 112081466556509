@import "../../../styles/variables";

.theme-hero {
    position: relative;
    z-index: 3;
    min-height: 100vh;
    height: 100%;

    width: 100%;

    display: flex;
    align-items: center;

    padding: 0 $padding-desktop-medium;
    margin-bottom: rem(40px);

    @include mq-mobile() {
        margin-bottom: rem(100px);
    }
}

.theme-hero-wrapper {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-white;
}

.theme-hero__content {
    position: relative;
    z-index: 2;

    text-align: center;
}

.theme-hero__title {
    text-transform: uppercase;

    font-size: 10vw;

    @include mq-mobile() {
        font-size: 12vw;
    }
}

.theme-hero__description {
    font-size: rem(20px);
    line-height: rem(26px);

    @include mq-mobile() {
        font-size: rem(34px);
        line-height: rem(42px);

        width: 60%;
        margin: 0 auto;
    }
}

.theme-hero__background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;

    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;

    pointer-events: none;

    img {
        position: absolute;
        left: 0;
        top: 0;

        width: 100vw;
        height: 100vh;

        object-fit: cover;
    }
}

.theme-hero__scroller {
    position: absolute;
    left: 50%;
    bottom: rem(50px);

    transform: rotate(90deg);
    color: $color-white;

    font-size: rem(20px);
    line-height: rem(26px);
    animation: bounce 2s infinite;

    @include mq-mobile() {
        font-size: rem(34px);
        line-height: rem(42px);
    }

    @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0) rotate(90deg);
        }

        40% {
            transform: translateY(-30px) rotate(90deg);
        }

        60% {
            transform: translateY(-15px) rotate(90deg);
        }
    }
}

.themes-background__credit {
    position: absolute;
    bottom: rem(25px);
    right: $padding-desktop-small;
    z-index: 2;

    color: $color-white;
}
