@import "../../styles/variables";
@import "../../styles/layout";
@import "../../styles/typography";

.article-content__title {
  margin-bottom: rem(40px);
  @include mq-mobile() {
    margin-bottom: rem(60px);
  }
}
