@import "../../../styles/variables";
@import "../../../styles/typography";

.container {
  position: relative;
  padding: 0 $padding-mobile-small;

  width: 100%;
  margin: 0 auto;
}
