@import "./variables";

button {
    font-family: $font-regular;
}

.title {
    font-size: rem(24px);
    @include mq-mobile() {
        font-size: rem(50px);
    }
}

.body {
    font-size: rem(16px);
    line-height: rem(22px);

    @include mq-mobile() {
        font-size: rem(20px);
        line-height: rem(30px);
    }
}

.tagline {
    font-size: rem(14px);
    font-weight: 400;

    text-transform: uppercase;

    &--white {
        color: $color-white;
    }
}

.question__answers-grid {
    display: flex;
    margin-left: rem(-30px);
    width: auto;
}

.question__answers-grid__item {
    padding-left: rem(30px);
    background-clip: padding-box;
}

.link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.richtext {
    h2 {
        margin-bottom: rem(30px);
    }

    h3 {
        margin-bottom: rem(25px);
    }

    p {
        margin-bottom: rem(15px);
    }

    ul {
        margin-bottom: rem(15px);

        li {
            list-style: disc;

            &:not(:last-child) {
                margin-bottom: rem(10px);
            }
        }
    }

    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
